import { useCallback, useState } from 'react';

export interface UsePasswordUpdateIndicatorResult {
  hasPasswordBeenUpdated: boolean;
  setIndicator: (value: boolean) => void;
}

export const passwordUpdateIndicatorKey = 'password-updated';

export const passwordUpdateIndicatorValue: string = 'true';

const getIndicator = (): boolean => {
  try {
    return (
      sessionStorage.getItem(passwordUpdateIndicatorKey) ===
      passwordUpdateIndicatorValue
    );
  } catch {
    return false;
  }
};

const setIndicator = (value: boolean) => {
  try {
    if (value) {
      sessionStorage.setItem(
        passwordUpdateIndicatorKey,
        passwordUpdateIndicatorValue
      );
    } else {
      sessionStorage.removeItem(passwordUpdateIndicatorKey);
    }
  } catch {
    /* noop */
  }
};

export const usePasswordUpdateIndicator = (): UsePasswordUpdateIndicatorResult => {
  const [isIndicatorActive, setIsIndicatorActive] = useState(getIndicator);

  const setIndicatorScoped = useCallback(
    (value: boolean) => {
      setIndicator(value);
      setIsIndicatorActive(value);
    },
    [setIsIndicatorActive]
  );

  return {
    hasPasswordBeenUpdated: isIndicatorActive,
    setIndicator: setIndicatorScoped,
  };
};
